<template>
  <header>
    <router-link to="/" class="logo">
      <svg viewBox="0 0 300 53" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.021 26.0885C46.6056 24.6415 44.912 23.496 43.0422 22.7209C41.1724 21.9459 39.165 21.5572 37.141 21.5785C33.141 21.5785 32.931 18.7685 32.931 16.3885C32.9783 14.2237 32.5782 12.0726 31.7557 10.0697C30.9332 8.06675 29.706 6.2552 28.151 4.74846C25.0212 1.70807 20.8295 0.00732422 16.466 0.00732422C12.1026 0.00732422 7.9109 1.70807 4.78104 4.74846C1.71784 7.84822 0 12.0305 0 16.3885C0 20.7464 1.71784 24.9287 4.78104 28.0285C6.30667 29.5695 8.12963 30.7843 10.1392 31.5991C12.1488 32.4139 14.303 32.8118 16.471 32.7685C17.476 32.7737 18.4797 32.6935 19.471 32.5285C20.0776 32.4269 20.6991 32.4586 21.2922 32.6212C21.8854 32.7838 22.436 33.0735 22.906 33.4703C23.376 33.867 23.7541 34.3612 24.0139 34.9187C24.2738 35.4761 24.4093 36.0834 24.411 36.6985V52.9885H37.141C39.1687 53.0366 41.1841 52.6602 43.0578 51.8835C44.9314 51.1069 46.622 49.947 48.021 48.4785C50.8364 45.4302 52.3999 41.433 52.3999 37.2834C52.3999 33.1339 50.8364 29.1368 48.021 26.0885ZM16.471 24.9185C14.5694 24.8665 12.7441 24.1598 11.3033 22.9177C9.86245 21.6756 8.89453 19.9742 8.563 18.1011C8.23148 16.2279 8.55667 14.2977 9.48364 12.6365C10.4106 10.9753 11.8826 9.68505 13.6508 8.9836C15.4191 8.28214 17.3752 8.21252 19.1889 8.7865C21.0025 9.36047 22.5624 10.5429 23.6052 12.1339C24.6479 13.725 25.1095 15.6272 24.9119 17.5192C24.7144 19.4112 23.8699 21.1771 22.521 22.5185C21.7281 23.3079 20.7828 23.9277 19.7427 24.3403C18.7026 24.7529 17.5895 24.9496 16.471 24.9185ZM42.471 43.0485C41.7869 43.7703 40.9568 44.3381 40.036 44.714C39.1152 45.0899 38.1249 45.2653 37.131 45.2285H32.601V29.3385H37.131C38.1249 29.3016 39.1152 29.477 40.036 29.8529C40.9568 30.2288 41.7869 30.7966 42.471 31.5185C43.8097 33.1414 44.5419 35.1796 44.5419 37.2834C44.5419 39.3873 43.8097 41.4255 42.471 43.0485Z" fill="url(#paint0_linear_668_6044)"/>
        <path class="odelib" d="M113.471 39.3549C110.012 42.703 105.386 44.5748 100.571 44.5748C95.7568 44.5748 91.131 42.703 87.6713 39.3549C84.2935 35.9289 82.3999 31.3111 82.3999 26.4999C82.3999 21.6888 84.2935 17.0709 87.6713 13.6449C91.131 10.2969 95.7568 8.42505 100.571 8.42505C105.386 8.42505 110.012 10.2969 113.471 13.6449C116.854 17.0684 118.751 21.6872 118.751 26.4999C118.751 31.3126 116.854 35.9314 113.471 39.3549ZM93.8813 33.2649C95.4546 34.9455 97.5648 36.0246 99.8483 36.3163C102.132 36.6079 104.446 36.0938 106.391 34.8626C108.336 33.6315 109.791 31.7603 110.504 29.5716C111.218 27.3829 111.145 25.0139 110.3 22.8728C109.454 20.7317 107.888 18.9527 105.871 17.8423C103.855 16.732 101.514 16.3599 99.2526 16.7903C96.9911 17.2206 94.9506 18.4263 93.4827 20.1996C92.0148 21.973 91.2115 24.2029 91.2113 26.5049C91.1722 27.7542 91.3888 28.9983 91.848 30.1607C92.3071 31.3232 92.9991 32.3796 93.8813 33.2649Z"/>
        <path class="odelib" d="M146.871 9.16492C149.11 9.11217 151.335 9.52995 153.402 10.3912C155.469 11.2525 157.332 12.5381 158.871 14.1649C161.976 17.5302 163.7 21.9411 163.7 26.5199C163.7 31.0987 161.976 35.5096 158.871 38.8749C157.332 40.5017 155.469 41.7873 153.402 42.6486C151.335 43.5099 149.11 43.9277 146.871 43.8749H132.761V9.16492H146.871ZM146.871 35.2649C147.968 35.3038 149.061 35.11 150.078 34.6963C151.095 34.2827 152.013 33.6586 152.771 32.8649C154.25 31.0733 155.059 28.8228 155.059 26.4999C155.059 24.177 154.25 21.9265 152.771 20.1349C152.013 19.3413 151.095 18.7171 150.078 18.3035C149.061 17.8898 147.968 17.6961 146.871 17.7349H141.871V35.2649H146.871Z"/>
        <path class="odelib" d="M186.641 35.5149H200.061V43.8349H177.731V9.16492H199.731V17.4349H186.641V22.2449H198.581V30.4149H186.641V35.5149Z"/>
        <path class="odelib" d="M222.991 35.2649H234.931V43.8349H214.131V9.16492H222.991V35.2649Z"/>
        <path class="odelib" d="M248.051 9.16492H256.911V43.8349H248.051V9.16492Z"/>
        <path class="odelib" d="M294.161 25.9049C295.539 26.6683 296.676 27.8011 297.444 29.1755C298.213 30.55 298.582 32.1117 298.511 33.6849C298.568 35.0737 298.313 36.4576 297.764 37.7348C297.216 39.0119 296.388 40.1499 295.341 41.0649C292.963 43.0061 289.947 43.9934 286.881 43.8349H272.271V9.16491H285.941C288.94 9.01638 291.892 9.95761 294.251 11.8149C295.283 12.7027 296.099 13.8127 296.64 15.0615C297.18 16.3103 297.43 17.6654 297.371 19.0249C297.424 20.3446 297.16 21.6576 296.601 22.8545C296.043 24.0513 295.206 25.0972 294.161 25.9049ZM285.691 17.0949H281.131V22.5349H285.691C286.424 22.5594 287.138 22.3058 287.691 21.8249C287.968 21.5732 288.185 21.2632 288.327 20.9173C288.468 20.5714 288.532 20.1982 288.511 19.8249C288.531 19.4543 288.471 19.0838 288.335 18.7387C288.198 18.3935 287.989 18.0818 287.721 17.8249C287.167 17.324 286.438 17.0618 285.691 17.0949ZM286.631 35.9149C287.031 35.9305 287.43 35.8659 287.804 35.7251C288.178 35.5843 288.521 35.37 288.811 35.0949C289.09 34.8206 289.308 34.4914 289.453 34.1282C289.598 33.765 289.665 33.3756 289.651 32.9849C289.67 32.5907 289.605 32.1969 289.46 31.8298C289.315 31.4626 289.094 31.1303 288.811 30.8549C288.215 30.3186 287.433 30.0353 286.631 30.0649H281.131V35.9149H286.631Z"/>
        <defs>
          <linearGradient id="paint0_linear_668_6044" x1="2.57321" y1="26.4999" x2="49.3588" y2="26.4999" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F39FA3"/>
            <stop offset="1" stop-color="#FF7A6F"/>
          </linearGradient>
        </defs>
      </svg>

    </router-link>

    <router-link v-if="!auth.logged" to="/login" class="connected">
      <strong>Connexion</strong>
      <svg class="ico" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/></svg>
    </router-link>
    <div v-if="auth.logged" @click="openNav=true" class="connected">
      <span><strong>{{ auth.user.firstname }} {{ auth.user.name }}</strong>{{ auth.user.email }}</span>
      <svg class="ico" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/></svg>
    </div>
    <nav v-if="auth.logged && openNav">
      <ul>
        <li><router-link class="freeze" @click="openNav=false" to="/account">Paramètres compte</router-link></li>
        <li><router-link @click="openNav=false" to="/eval">Mes évaluations</router-link></li>
        <li><router-link class="freeze" @click="openNav=false" to="/forfait">Mes forfait</router-link></li>
        <li><router-link class="freeze" @click="openNav=false" to="/schools">Mes écoles</router-link></li>
      </ul>
      <div class="logout" @click="openNav=false;auth.logout()">Se déconnecter</div>
    </nav>
  </header>
</template>

<script>
import { auth } from '../auth.js'

export default {
  name: 'HeaderView',
  data() {
    return {
      auth,
      openNav:false
    }
  },
  methods: {
    handleClickOutside(event) {
      if(this.openNav){
        // console.log(this.$el.querySelector('.connected'))
        if(this.$el.querySelector('.connected')){
          if(this.$el.querySelector('nav')){
            if(!this.$el.querySelector('.connected').contains(event.target) && !this.$el.querySelector('nav').contains(event.target)) {
              this.openNav = false;
            }
          }
        }
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
  // props: {
  //   msg: String
  // }
}
</script>

<style scoped lang="scss">
header{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: .1rem solid rgba(white,.5);
  margin: 2rem 5vw;
  padding: 0 0 2rem 0;
  gap: 2rem;
  .logo{
    width: 26rem;
    height: auto;
    svg{
      display: block;
      .odelib{
        fill: white;
      }
    }
  }
}

.connected{
  display: flex;
  border: .1rem solid white;
  padding: 1rem 1.5rem;
  border-radius: $radius*2;
  font-size: 1.2rem;
  text-align: right;
  gap: 1rem;
  cursor: pointer;
  align-items: center;
  color: white;
  text-decoration: none;

  .ico{
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    fill: white;
  }
  strong{
    display: block;
    font-size: 1.6rem;
  }
}

nav {
  display: block;
  position: absolute;
  z-index: 99;
  right: 0;
  top: calc(100% - 1rem);
  padding: 0.5rem;
  border-radius: $radius*2;
  background: $grey;
  min-width: 20rem;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 600;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);

  ul{
    background: white;
    list-style: none;
    margin: 0;
    padding: 0.5rem 0;
    border-radius: $radius*1.7;
    margin-bottom: 0.5rem;

    li{
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      border-bottom: 0.1rem solid $grey;

      &:last-child{
        border-bottom: 0px none;
      }
    }
  }
  a {
    display: block;
    color: $dark;
    text-decoration: none;
    padding: 1rem;

    &.freeze{
      opacity: 0.5;
      pointer-events: none;
    }
    &.router-link-exact-active {
      color: $pink;
    }
  }
  .logout{
    display: block;
    color: #6c6c6c;
    padding: 1rem;
    cursor: pointer;
  }
}
</style>
