import { reactive } from 'vue'
import axios from "axios"

export const auth = reactive({
  logged: false,
  token: localStorage.getItem("token")? localStorage.getItem("token") : "",
  user: false,
  actionLogout: ()=> {},
  async autologin() {
    console.log("autoLogin")
    try {
      const res = await axios.post(`https://api.odelib.com/login`,{token:this.token})
      console.log(res.data)
      if(res.data.connected){
        this.logged = true
        this.token = res.data.token
        this.user = res.data.user
        localStorage.setItem("token", this.token)
      }
    }catch(e){
      console.error(e)
    }
  },
  async login(user,pass) {
    try {
      const res = await axios.post(`https://api.odelib.com/login`,{token:this.token,user:user,password:pass})
      // const res = await axios.post(`https://api.odelib.com/login?token=${this.token}`,{user:"ludovicwellens",password:"lkqjsdlkjqds"})
      console.log(res.data)
      if(res.data.connected){
        this.logged = true
        this.token = res.data.token
        this.user = res.data.user
        localStorage.setItem("token", this.token)
        return true
      }else{
        return false
      }
    }catch(e){
      console.error(e)
      return false
    }
  },
  logout() {
    this.logged = false
    this.user = false
    this.token = ""
    localStorage.removeItem("token")
    this.actionLogout()
  },
  async signin(name,firstname,email,password) {
    try {
      // const res = await axios.post(`https://api.odelib.com/register?name=${name}&firstname=${firstname}&email=${email}&password=${password}`)
      const res = await axios.post(`https://api.odelib.com/register`,{name:name,firstname:firstname,email:email,password:password})
      console.log(res.data)
      if(res.data.connected){
        this.logged = true
        this.token = res.data.token
        this.user = res.data.user
        localStorage.setItem("token", this.token)
        return true
      }else{
        return false
      }
    }catch(e){
      console.error(e)
      return false
    }
  }
})