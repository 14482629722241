import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { header: true },
    // component: HomeView
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { header: true },
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    meta: { header: true },
    component: () => import('../views/SigninView.vue')
  },
  {
    path: '/jury/:id',
    name: 'jury',
    meta: { header: false },
    component: () => import('../views/JuryList.vue')
  },
  {
    path: '/eval/:id',
    name: 'evaluation',
    meta: { header: true },
    component: () => import('../views/EvalView.vue')
  },
  {
    path: '/points/:id',
    name: 'points',
    meta: { header: true },
    component: () => import('../views/PointsView.vue')
  },
  {
    path: '/eval',
    name: 'evaluations',
    meta: { header: true },
    component: () => import('../views/EvalsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
