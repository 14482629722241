import Vue from 'vue'
import { createApp } from 'vue'
import VueQrcode from 'vue-qrcode-component'
import Loader from './components/Loader.vue'
import App from './App.vue'
import router from './router'
import { VueDraggableNext } from 'vue-draggable-next'


String.prototype.nl2br = function (isXhtml) {
    const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br ' + '/>' : '<br>'
    return this.replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1')
}


const app = createApp(App)
app.use(router)


app.component('qr-code', VueQrcode)
app.component('loader', Loader)
app.component('draggable', VueDraggableNext)
app.component('transition-group', VueDraggableNext)


app.mount('#app')

