<template>
  <HeaderView v-if="header" />
  <div class="Content" v-if="firstLoad">
    <router-view/>
  </div>
  
  <loader v-if="!firstLoad" />
</template>

<script>
import HeaderView from '@/components/HeaderView.vue'
import {auth} from '@/auth.js'

export default {
  components: {
    HeaderView
  },
  watch: {
    '$route' (to, from) {
      this.header = this.$router.currentRoute._value.meta.header
    }
  },
  data() {
    return {
      firstLoad: false,
      header: this.$router.currentRoute._value.meta.header
    }
  },
  methods: {
    toggleShow: function() {
      console.log(this.$router.currentRoute._value.meta.header)
      // this.isShowing = !this.isShowing;
      // setTimeout(() => {
      //   this.toggleShow();
      // }, 800);

    }
  },
  async mounted() {
    auth.actionLogout = ()=> {
      this.$router.push({ name: 'home' })
    }
    await auth.autologin()
    this.firstLoad = true
  }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: white;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $blue;
  border-radius: 5px;
  border: 2px solid white;
}

::-webkit-scrollbar-thumb:hover {
  background: $dark;
}

html{
  overflow-y: visible;
  scrollbar-width: thin;
  overflow-y: scroll;
  font-size: 10px;
}
body {
  padding: 0;
  margin: 0;
  background-color: $blue;
  background-image: linear-gradient(100deg, $blue 15%, $blue2 95%);
}
#app {
  font-size: 1.8rem;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}



.login{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 70rem;
  width: 90vw;
  gap: 1rem;
  padding: 2rem 0;
  box-sizing: border-box;
  min-height: calc(100vh - 10.7rem);

  &-btns{
    display: flex;
    gap: 1rem;

    .btn{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border: .1rem solid white;
      border-radius: $radius;
      color: white;
      flex-grow: 1;
      text-decoration: none;

      &.active{
        pointer-events: none;
        border: 0px none;
        background-image: linear-gradient(60deg,$pink,$red);
      }
    }
  }
  .form{
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input{
      padding: 1rem;
      border: .1rem solid white;
      border-radius: $radius;
      background: transparent;
      color: white;
      outline: 0px none;
      
      &::placeholder{
        color: white;
      }
    }
    button{
      padding: 1rem 4rem;
      border: 0px none;
      border-radius: $radius;
      color: white;
      cursor: pointer;
      background-image: linear-gradient(60deg,$pink,$red);
      align-self: flex-end;
    }
  }
  .error{
    text-align: right;
    color: $red;
  }
}



.overlay{
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(black,0.8);

  &-in{
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    width: 90vw;
    max-width: 70rem;
    min-height: 100vh;
    padding: 5vw 0;
    margin: auto;
    
    &.slim{
      width: 30rem;
    }
  }
  
  .actions{
    display: flex;
    justify-content: flex-end;
    gap: $gap;

    button{
      display: block;
      line-height: 2rem;
      padding: .5rem 2rem;
      border: 0px none;
      border-radius: $radius/2;
      background: white;
      cursor: pointer;

      &.icon{
        padding: .5rem;
      }
      svg{
        display: block;
        width: 2rem;
        height: 2rem;
      }
      &.save,&.refuse{
        background-image: linear-gradient(60deg,$pink,$red);
        color: white;
      }
    }
  }
  .bloc{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    background: $blue;
    color: white;
    padding: 2rem;
    border-radius: $radius;
    text-align: left;
    margin-top: 1rem;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    .bloc{
      padding: 1rem;
      margin-top: 0;
      
      hr{
        margin: 0;
      }
    }
    h2{
      width: 100%;
      color: $red;
      font-weight: 600;
      font-size: 1.4rem;
      margin: 0;
      text-transform: uppercase;
    }
    hr{
      display: block;
      width: 100%;
      height: .1rem;
      border: 0px none;
      display: block;
      background: white;
      margin: 0.5rem 0;
    }
    .qrcode{
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .multiline{
        display: flex;
        flex-direction: column;
        gap: 1rem;
      .multi{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        button{
          align-self: flex-end;
        }
      }
      button{
        display: block;
        line-height: 2rem;
        padding: .5rem 2rem;
        border: 0px none;
        border-radius: $radius/2;
        color: white;
        background: $dark;
        cursor: pointer;
      }
      .remove{
        display: block;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        cursor: pointer;

        svg{
          width: 2rem;
          height: 2rem;
        }
      }
    }
    .line{
      position: relative;
      width: 100%;
      
      &-grow{
        width: auto;
        flex-grow: 1;
      }
      &-1{
        width: 100%;
      }
      &-2{
        width: 50%;
      }
      &-3{
        width: 33.33%;
      }
      &-4{
        width: 25%;
      }
      &-5{
        width: 20%;
      }
      label{
        display: block;
        padding: .5rem 0;
        font-size: 1.4rem;
      }
      input,textarea{
        font: inherit;
        font-size: 1.4rem;
        width: 100%;
        background: $grey;
        color: $dark;
        box-sizing: border-box;
        height: 3rem;
        line-height: 2rem;
        padding: .5rem 1rem;
        border: 0px none;
        border-radius: $radius/2;
        outline: 0px none;
        resize: none;
      }
      .checkbox{
        display: inline-block;
        border: 0.5rem solid $grey;
        background: white;
        border-radius: 50%;
        height: 1.4rem;
        width: 1.4rem;
        cursor: pointer;
        vertical-align: middle;
        margin: 0.3rem 0.5rem 0.4rem 0rem;

        &.checked{
          background: $dark;
        }
        + input{
          width: calc(100% - 2.9rem );
        }
      }
      .picture{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 15rem;
        background: $grey;
        border-radius: $radius/2;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        .img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          pointer-events: none;
          
          img{
            display: block;
            height: 100%;
            margin: auto;
          }
          svg{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: block;
            height: 10rem;
            fill: $red;
          }
        }
        input{
          opacity: 0;
          text-indent: -100rem;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
    
    &-grey{
      background: $grey;
      color: $dark;

      hr{
        background: $dark;
      }
      h2{
        color: $dark;
      }
      .line{
        input,textarea{
          background: white;
        }
      }
    }
    &-white{
      background: white;
      color: $dark;

      hr{
        background: $grey;
      }
      h2{
        color: $dark;
      }
      .line{
        input,textarea{
          background: $grey;
        }
      }
    }
    &-dark{
      background: $dark;
      color: white;

      hr{
        background: $blue;
      }
      h2{
        color: white;
      }
      .line{
        input,textarea{
          background: $blue;
        }
      }
    }
  }
}

</style>
